var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":!_vm.item.errors}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("Ver log de erros")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',[_vm._v(" Log de erros ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Data ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Descrição ")])])]),_c('tbody',_vm._l((_vm.item.errors),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("date")(item.date,'dd/MM/yyyy HH:mm')))]),_c('td',[_vm._v(_vm._s(item.type))])])}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('mf-action-buttons',{attrs:{"primary-button":{
          text: 'Fechar',
          action: function () { return (_vm.dialog = false); },
          isVisible: true
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }