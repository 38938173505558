<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" :disabled="!item.errors" v-on="on">
        <v-list-item-title>Ver log de erros</v-list-item-title>
      </v-list-item>
    </template>

    <v-card style="overflow-x:hidden">
      <v-card-title>
        Log de erros
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Data
                </th>
                <th class="text-left">
                  Descrição
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in item.errors" :key="index">
                <td>{{ item.date | date('dd/MM/yyyy HH:mm') }}</td>
                <td>{{ item.type }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <mf-action-buttons
          :primary-button="{
            text: 'Fechar',
            action: () => (dialog = false),
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
